import React from 'react'
import classes from './Menu.module.css'
import MenuButtons from '../MenuButtons/MenuButtons'





const Menu = () =>{

    return(
        <>
            <div className={classes.left__menu}>
                <MenuButtons link="/Vendedores">Vendedores</MenuButtons>
                <MenuButtons link="/">Afiliados</MenuButtons>
            </div>
        </>
    )
}

export default Menu