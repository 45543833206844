import { toast } from "react-toastify";

class ErrorWithToast extends Error {
  constructor(message) {
    super(message);

    this.display = (apiMessage) => {
      toast.error(message);
      console.log(this.stack);
    }
  }
}

export default ErrorWithToast;
