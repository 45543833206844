import React, { useContext, useEffect, useState } from 'react';
import useApi from './useApi';
import Auth from '../Services/Auth';
import MaisAfiliados from '../Services/MaisAfiliados';

const UserContext = React.createContext();

const UserContextProvider = props => {

  const storedUser = localStorage.getItem("CurUser");
  const login = useApi(Auth.login);
  const logoff = useApi(Auth.logoff);
  const retoken = useApi(Auth.retoken);
  const createAccount = useApi(Auth.createAccount);
  const [user, setUser] = useState(Auth.decodeUser(storedUser));
  const [userType, setUserType] = useState(null);

  useEffect(() => {
    if (login.data) {
      localStorage.setItem("CurUser", login.data);
    }
  }, [login.data]);

  useEffect(() => {
    if (retoken.data) {
      localStorage.setItem("CurUser", retoken.data);
    }
  }, [retoken.data]);

  useEffect(() => {
    const decodedUser = Auth.decodeUser(storedUser);
    setUser(decodedUser);
    if (storedUser && decodedUser.id_affiliates) {
      MaisAfiliados.get(`v1/user/affiliates/id/${decodedUser.id_affiliates}`).then(res => {
        setUserType(res.data.profile.id_profiles);
      });
    }
  }, [storedUser]);

  useEffect(() => {
    if (logoff.data) localStorage.removeItem("CurUser");
  }, [logoff.data]);

  useEffect(() => {
    const logoffDispatch = logoff.dispatch;
    const checkExpiry = () => user && (user.exp <= new Date().getTime() / 1000) && logoffDispatch();
    checkExpiry();
    const interval = setInterval(checkExpiry, 10000);
    return () => { clearInterval(interval); };
  }, [user, logoff.dispatch]);

  return (
    <UserContext.Provider value={{ logoff: logoff.dispatch, user, userType, login: login.dispatch, createAccount: createAccount.dispatch, retoken: retoken.dispatch}}>
      {props.children}
    </UserContext.Provider>
  );
};

const useUser = () => {
  const { user } = useContext(UserContext);

  return user;
};

const useLogin = () => {
  const { login, logoff, retoken } = useContext(UserContext);

  return { login, logoff, retoken };
};

const useCreateAccount = () => {
  const { createAccount } = useContext(UserContext);

  return createAccount;
};

const useUserProfile = () => {
  const { userType } = useContext(UserContext);

  return userType;
};

export { UserContextProvider, useLogin, useCreateAccount, useUserProfile };

export default useUser;
