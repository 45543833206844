import axios from 'axios';
import MaisAfiliados from './MaisAfiliados';

const decodeUser = (tok) => {
  if (!tok) return undefined;
  try {
    const [, data,] = tok.split('.');
    return JSON.parse(atob(data.replace('-', '+').replace('_', '\\')));
  } catch (e) {
    return undefined;
  }
};

const login = async (login, senha) => {
  try {
    return await MaisAfiliados.post('/v1/auth/login', { login, senha },
      {
        responseType: "text",
        transformRequest: axios.defaults.transformRequest
      }
    );
  } catch (err) {
    throw err.response.data;
  }
};

const retoken = async (code) => {
  try {
    return await MaisAfiliados.post('/v1/auth/retoken', { code },
      {
        responseType: "text",
        transformRequest: axios.defaults.transformRequest,
      }
    );
  } catch (err) {
    throw err;
  }
};

const createAccount = async (email) => {
  try {
    return await MaisAfiliados.post('/v1/auth/createAccount', { email },
      {
        responseType: "text",
        transformRequest: axios.defaults.transformRequest
      }
    );
  } catch (err) {
    throw err;
  }
};

const logoff = async () => {
  try {
    return await MaisAfiliados.post("v1/auth/logoff");
  } catch (err) {
    throw err;
  }
};

const Auth = { login, retoken, decodeUser, createAccount, logoff };

export default Auth;
