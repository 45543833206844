import { useCallback, useState } from 'react';
import ErrorHandler from '../Errors/ErrorHandler';

const useApi = (api) => {
  const [status, setStatus] = useState('ready');
  const [data, setData] = useState(undefined);
  const [metadata, setMetadata] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [seen, setSeen] = useState({});

  const dispatch = useCallback(async (...args) => {
    setStatus('pending');
    setData(undefined);
    setMetadata(undefined);
    setError(undefined);
    setSeen({});
    try {
      const { data, ...metadata } = await api(...args);
      setStatus('ready');
      setData(data);
      setMetadata(metadata);
      setSeen({});
    } catch (e) {
      const { error, ...metadata } = ErrorHandler(e);
      setStatus('ready');
      setMetadata(metadata);
      setError(error || e);
      setSeen({});
    }
  }, [api]);

  const stale = useCallback((name) => {
    const hasSeen = seen[name];
    if (!hasSeen) setSeen((prev) => {
      prev[name] = new Date();
      return prev;
    });
    return hasSeen;
  }, [seen]);

  return {dispatch, status, data, error, metadata, stale, seen};
}
export default useApi;
