import { toast } from "react-toastify";
import ErrorWithToast from "./ErrorWithToast";

class BadDevNoCoffee extends ErrorWithToast {
  constructor(error) {
    super(error?.message || "Algo de errado aconteceu :(");

    //this.stack = error.stack

    this.display = (apiMessage) => {
      toast.error(apiMessage || this.message);
      console.log(this.stack);
    }
  }
}

export default BadDevNoCoffee;
