import React from 'react'
import classes from './CardVendedor.module.css'
import CardVendedorInfo from './CardVendedorInfo/CardVendedorInfo'


const cardVendedor = props =>{

    const { config, ...rest } = props;

    return (
        <div className={classes.cardVendedor} {...rest} >
            <div className={classes.cardVendedor__line}></div>
            <h1 className={classes.cardVendedor__title}>Codigo {config.affiliate_code} </h1>
            <div className={classes.cardVendedor__info__items}>
                <CardVendedorInfo title="CPF" vendedorInfo={config.info.CPF} />
                <CardVendedorInfo title="Nome Completo"  config={config.info} vendedorInfo={config.info.full_name}/>
                <CardVendedorInfo title="Email" config={config.info} vendedorInfo={config.info.email}/>
                <CardVendedorInfo title="Codigo" config={config.info} vendedorInfo={config.affiliate_code}/>
                <button className={classes.delete__btn}>Deletar</button>
            </div>
        </div>
        
    ) 
}

export default cardVendedor