import {UserContextProvider} from './Hooks/UserContext';
import Routes from './Routes'
import { ToastContainer } from 'react-toastify'
import "react-toastify/dist/ReactToastify.css"
import { AffiliatesContextProvider } from './Hooks/AffiliatesContext';



function App() {
  return (
    <div className="App">
      <ToastContainer
        position={"bottom-center"}
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick={true}
        pauseOnHover={true}
        draggable={true}
        />
      <UserContextProvider>
        <AffiliatesContextProvider>
          <Routes/>
        </AffiliatesContextProvider>
      </UserContextProvider>
    </div>
  );
}

export default App;
