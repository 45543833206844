import BadDevNoCoffee from "./BadDevNoCoffee";
import CantReachBackend from "./CantReachBackend";
import CantReachDB from "./CantReachDB";
import ErrorWithToast from "./ErrorWithToast";
import LoginFail from "./LoginFail";
import NeedsLogin from "./NeedsLogin";

const errorSwitch = (e) => {
  if (e instanceof ErrorWithToast) {
    return e;
  }
  if (!e) {
    return new BadDevNoCoffee(e);
  }
  if (e.response) {
    if (e.response.data.errorId) {
      return backendErrors(e.response.data);
    }
  }
  if (e.message === 'Network Error') {
    return new CantReachBackend();
  }

  return new BadDevNoCoffee(e);
}

const backendErrors = (error) => {
  debugger
  switch (error.errorId) {
    case "connection-error":
      return new CantReachDB();
    case "login-fail":
      return new LoginFail();
    case "user-token-absent":
      return new NeedsLogin();
    case "user-token-invalid":
      // eslint-disable-next-line no-restricted-globals
      location.assign('/logoff');
      return new Error("");
    case "user-not-found":
      return new CantReachDB();
    case "bad-token-gen":
    case "user-email-invalid":
    default:
      return new BadDevNoCoffee(error);
  }
}

const ErrorHandler = (err) => {
  const displayError = errorSwitch(err);
  if (displayError instanceof ErrorWithToast) {
    displayError.display();
  }

  return { error: err };
}

export default ErrorHandler
