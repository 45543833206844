import React, { useCallback, useMemo, useState } from 'react';
import classes from './home.module.css';
import LoginForm from './Login/LoginForm/LoginForm';
import CreateAccountModal from './Login/CreateAccountModal/CreateAccountModal';


const Home = () => {
  const [createAccountModal, setCreateAccountModal] = useState(false);

  const ShowCreateAccountModalHandler = useCallback(() => {
    setCreateAccountModal(true);
  }, []);

  const HideCreateAccountModalHandler = useCallback(() => {
    setCreateAccountModal(false);
  }, []);

  const createAccountModalElement = useMemo(() => <CreateAccountModal show={createAccountModal} cancelModal={HideCreateAccountModalHandler} />, [createAccountModal, HideCreateAccountModalHandler]);

  return (
    <div className={classes.home__page}>
      {createAccountModalElement}
      <LoginForm clickedModalCreate={ShowCreateAccountModalHandler} />
    </div>
  );
};

export default Home;
