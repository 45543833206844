import React from 'react'
import classes from './CardVendedorInfo.module.css'


const cardVendedorInfo = props=>{

    const { title,vendedorInfo  } = props;
 
    return(
        <div className={classes.cardVendedorInfo} key={title}>
            <span style={{fontSize:"1.3rem",marginBottom:"5px"}}>{title}</span>
            <span  style={{fontSize:"1rem"}}>{vendedorInfo}</span>
        </div>
    )
}

export default cardVendedorInfo