
const validationForms = (type, rules, value) => {
    
    let isValid = true

    if (rules.required) {
        isValid = value !== "" && isValid
    }

    if (rules.minLength) {
        isValid = value.length >= rules.minLength && isValid
    }

    if (rules.maxLength) {
        isValid = value.length <= rules.maxLength && isValid
    }

    return isValid

}

const disabledBtnHandler = value => {
    let disabledBtn = false
    const formBtnConfig = []

    for (let key in value) {
        formBtnConfig.push({
            config: value[key]
        })
    }
    formBtnConfig.map(e => {
        if (!e.config.valid) {
            disabledBtn = true
        }
        return null
    })

    return disabledBtn
}

const validation = { validationForms, disabledBtnHandler }


export default validation