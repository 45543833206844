import React, { useCallback, useState } from 'react'
import classes from './CreateAccountModal.module.css'
import Validation from '../../../Helpers/Validation'
import { useCreateAccount } from '../../../Hooks/UserContext'
import Backdrop from '../../../components/Backdrop/Backdrop'
import SomaForm from '../../../components/SomaForm/SomaForm'

const CreateAccountModal = props => {

    const CreateAccount = useCreateAccount()
    const [inputItemsValue, setInputItemsValue] = useState({
        email: {
            value: "",
            valid: false,
            validationRules: {
                required: true,
                minLength: 4
            }
        }
    })
    let disabledBtn = Validation.disabledBtnHandler(inputItemsValue)


    const onCreateAccountEmailHandler = useCallback((e, type) => {
        const newInputItems = {
            ...inputItemsValue,
            [type]: {
                ...inputItemsValue[type],
                value: e.target.value,
                valid: Validation.validationForms(type, inputItemsValue[type].validationRules, e.target.value)
            }
        }
        setInputItemsValue(newInputItems)
    },[inputItemsValue])

    const onCreateAccount = useCallback( (event, email) => {
        event.preventDefault()
        return CreateAccount(email)
    },[CreateAccount])

    const config = [
        {inputType: "input",
        name: "email",
        isValidation: true,
        title: "E-mail",
        type: "email",
        value: inputItemsValue.email.value || '',
        changed: onCreateAccountEmailHandler}
    ]

    const formTitle = <h1 className={classes.title__create__account}> Cadastro +Afiliados </h1>

    return (
        <>
            <Backdrop show={props.show} clicked={props.cancelModal} />
            <SomaForm className={classes.form__create__account} formTitle={formTitle} style={props.show ? { display: "flex" } : { display: "none" }} config={config}>
                <div className={classes.buttons__create__account}>
                    <button className={classes.button__cancel} onClick={props.cancelModal}>Voltar</button>
                    <button className={classes.button__comfirm} onClick={(event) => onCreateAccount(event, inputItemsValue.email.value)} disabled={disabledBtn}>Continuar</button>
                </div>
            </SomaForm>
        </>
    )
}


export default CreateAccountModal