import React, { useCallback, useEffect, useState } from 'react'
import classes from './VendedoresForm.module.css'
import Validation from '../../../Helpers/Validation'
import SomaForm from '../../../components/SomaForm/SomaForm'
import useUser, { useUserProfile } from '../../../Hooks/UserContext'
import { useCreateAffiliates } from '../../../Hooks/AffiliatesContext'
import Backdrop from '../../../components/Backdrop/Backdrop'



const VendedoresForm = props => {

    const estados = [
        { text: 'Acre', value: 'AC' },
        { text: 'Alagoas', value: 'AL' },
        { text: 'Amapá', value: 'AP' },
        { text: 'Amazonas', value: 'AM' },
        { text: 'Bahia', value: 'BA' },
        { text: 'Ceará', value: 'CE' },
        { text: 'Distrito Federal', value: 'DF' },
        { text: 'Espírito Santo', value: 'ES' },
        { text: 'Goiás', value: 'GO' },
        { text: 'Maranhão', value: 'MA' },
        { text: 'Mato Grosso', value: 'MT' },
        { text: 'Mato Grosso do Sul', value: 'MS' },
        { text: 'Minas Gerais', value: 'MG' },
        { text: 'Pará', value: 'PA' },
        { text: 'Paraíba', value: 'PB' },
        { text: 'Paraná', value: 'PR' },
        { text: 'Pernambuco', value: 'PE' },
        { text: 'Piauí', value: 'PI' },
        { text: 'Rio de Janeiro', value: 'RJ' },
        { text: 'Rio Grande do Norte', value: 'RN' },
        { text: 'Rio Grande do Sul', value: 'RS' },
        { text: 'Rondônia', value: 'RO' },
        { text: 'Roraima', value: 'RR' },
        { text: 'Santa Catarina', value: 'SC' },
        { text: 'São Paulo', value: 'SP' },
        { text: 'Sergipe', value: 'SE' },
        { text: 'Tocantins', value: 'TO' }
    ]
    const user = useUser()
    const createAffiliates = useCreateAffiliates()
    const { formVendedoresHandler, getAffiliatesById, showFormVendedores } = props;
    const [inputItemsValue, setInputItemsValue] = useState({
        full_name: {
            valid: false,
            value: "",
            validationRules: {
                required: true
            }
        },
        email: {
            valid: false,
            value: "",
            validationRules: {
                required: true
            }
        },
        CPF: {
            valid: false,
            value: "",
            validationRules: {
                required: true
            }
        },
        phone_2: {
            valid: false,
            value: "",
            validationRules: {
                required: true
            }
        },
        phone: {
            valid: false,
            value: "",
            validationRules: {
                required: true
            }
        },
        CEP: {
            valid: false,
            value: "",
            validationRules: {
                required: true
            }
        },
        city: {
            valid: false,
            value: "",
            validationRules: {
                required: true
            }
        },
        state: {
            value: "AC",
            valid: true,
            validationRules: {

            }
        },
        PIS: {
            value: "",
            valid: false,
            validationRules: {
                required: true
            }
        },
        district: {
            value: "",
            valid: false,
            validationRules: {
                required: true
            }
        },
        street: {
            value: "",
            valid: false,
            validationRules: {
                required: true
            }
        }
    })
    let config = null
    let CepLookupEvent = 0
    let disabledbtn = Validation.disabledBtnHandler(inputItemsValue)
    let userType = useUserProfile()


    const parseCPF = (cpf) => cpf.match(/\d/g)?.slice(0, 11).map((c, i) => { if (i === 3 || i === 6) return '.' + c; if (i === 9) return '-' + c; return c; }).join('');
    const parseCep = (cep) => cep.match(/\d/g)?.slice(0, 8).map((c, i, l) => { if (i === 2) return '.' + c; if (i === 5) return '-' + c; return c; }).join('');
    const parseCPFBack = (cpf) => cpf.replace(/\D/g, '');
    const parseCepBack = (cep) => cep.replace(/\.|-/g, '')

    const onCepHandler = useCallback(async (cep) => {
        const ans = await fetch(`http://viacep.com.br/ws/${cep}/json/`).then(r => r.json())
        if (ans.erro === true) return

        const newInputItems = {
            ...inputItemsValue,
            city: {
                ...inputItemsValue.city,
                value: ans.localidade,
                valid: Validation.validationForms("city", inputItemsValue.city.validationRules, ans.localidade)
            },
            CEP: {
                ...inputItemsValue.CEP,
                value: parseCep(cep)
            },
            state: {
                ...inputItemsValue.state,
                value: ans.uf || "AC"
            },
            district: {
                ...inputItemsValue.district,
                value: ans.bairro,
                valid: Validation.validationForms("district", inputItemsValue.district.validationRules, ans.bairro)
            },
            street: {
                ...inputItemsValue.street,
                value: ans.logradouro,
                valid: Validation.validationForms("street", inputItemsValue.street.validationRules, ans.logradouro)
            }
        }
        setInputItemsValue(newInputItems)
    }, [inputItemsValue])

    const isValidation = useCallback((newInputParams) => {
        let newInputItems = null

        if (!config) return

        config.map(e => {
            if (!e.isValidation) {
                newInputItems = {
                    ...newInputParams,
                    [e.name]: {
                        ...newInputParams[e.name],
                        value: "null",
                        valid: true
                    }
                }
            }
            return null
        })
        setInputItemsValue(newInputItems)
    }, [config])

    const onClearForm = useCallback(() => {
        const newInput = {
            full_name: {
                valid: false,
                value: "",
                validationRules: {
                    required: true
                }
            },
            email: {
                valid: false,
                value: "",
                validationRules: {
                    required: true
                }
            },
            CPF: {
                valid: false,
                value: "",
                validationRules: {
                    required: true
                }
            },
            phone_2: {
                valid: false,
                value: "",
                validationRules: {
                    required: true
                }
            },
            phone: {
                valid: false,
                value: "",
                validationRules: {
                    required: true
                }
            },
            CEP: {
                valid: false,
                value: "",
                validationRules: {
                    required: true
                }
            },
            city: {
                valid: false,
                value: "",
                validationRules: {
                    required: true
                }
            },
            state: {
                value: "AC",
                valid: true,
                validationRules: {

                }
            },
            PIS: {
                value: "",
                valid: false,
                validationRules: {
                    required: true
                }
            },
            district: {
                value: "",
                valid: false,
                validationRules: {
                    required: true
                }
            },
            street: {
                value: "",
                valid: false,
                validationRules: {
                    required: true
                }
            }
        }

        setInputItemsValue(newInput)
        formVendedoresHandler()
        isValidation(newInput)
    }, [isValidation, formVendedoresHandler])

    const onVendedorFormHandler = (e, type) => {
        if (type === "CPF") {
            const newInputItems = {
                ...inputItemsValue,
                [type]: {
                    ...inputItemsValue[type],
                    value: parseCPF(e.target.value),
                    valid: Validation.validationForms(type, inputItemsValue[type].validationRules, e.target.value)
                }
            }
            return setInputItemsValue(newInputItems)
        }

        if (type === "CEP") {
            if (e.target.value.match(/\d/g)?.length === 8) {
                clearTimeout(CepLookupEvent)
                CepLookupEvent = setTimeout(() => onCepHandler(e.target.value.match(/\d/g).join('')), 300)
            }
            const newInputItems = {
                ...inputItemsValue,
                [type]: {
                    ...inputItemsValue[type],
                    value: parseCep(e.target.value),
                    valid: Validation.validationForms(type, inputItemsValue[type].validationRules, e.target.value)
                }
            }
            return setInputItemsValue(newInputItems)
        }

        const newInputItems = {
            ...inputItemsValue,
            [type]: {
                ...inputItemsValue[type],
                value: e.target.value,
                valid: Validation.validationForms(type, inputItemsValue[type].validationRules, e.target.value)
            }
        }
        return setInputItemsValue(newInputItems)
    }

    const OnSubmitForm = () => {
        let info = {}
        config.map(e => {
            if (!e.isValidation) return null

            switch (e.name) {
                case "CPF":
                    return info = {
                        ...info,
                        [e.name]: parseCPFBack(e.value)
                    }
                case "CEP":
                    return info = {
                        ...info,
                        [e.name]: parseCepBack(e.value)
                    }
                case "full_name":
                    return info = {
                        ...info,
                        [e.name]: e.value.toUpperCase()
                    }
                default:
                    return info = {
                        ...info,
                        [e.name]: e.value
                    }
            }
        })

        const formData = {
            affiliate: {
                affiliate_name: inputItemsValue.full_name.value,
                id_profiles: userType === 1 ? 1 : userType === 5 ? 6 : userType === 10 && 10
            },
            info: info,
            brands: [
                2
            ],
            parents: [
                user.id_affiliates
            ]
        }
        createAffiliates(formData)
        getAffiliatesById()
        onClearForm()
    }

    useEffect(() => {
        isValidation(inputItemsValue)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userType])

    const configProfile1 = [
        {
            inputType: "input",
            title: "Nome Completo",
            name: "full_name",
            isValidation: true,
            type: "text",
            value: inputItemsValue.full_name.value || '',
            changed: onVendedorFormHandler
        },
        {
            inputType: "input",
            title: "CPF",
            isValidation: true,
            name: "CPF",
            type: "text",
            value: inputItemsValue.CPF.value || '',
            changed: onVendedorFormHandler
        },
        {
            inputType: "input",
            title: "CEP",
            isValidation: true,
            type: "text",
            name: "CEP",
            value: inputItemsValue.CEP.value || '',
            changed: onVendedorFormHandler
        },
        {
            inputType: "input",
            name: "phone",
            isValidation: true,
            title: "Celular",
            type: "text",
            value: inputItemsValue.phone.value || '',
            changed: onVendedorFormHandler
        },
        {
            inputType: "input",
            title: "Cidade",
            isValidation: true,
            name: "city",
            type: "text",
            value: inputItemsValue.city.value || '',
            changed: onVendedorFormHandler
        },
        {
            inputType: "input",
            name: "email",
            isValidation: true,
            title: "E-mail",
            type: "email",
            value: inputItemsValue.email.value || '',
            changed: onVendedorFormHandler
        },
        {
            inputType: "input",
            title: "Telefone",
            isValidation: true,
            name: "phone_2",
            type: "text",
            value: inputItemsValue.phone_2.value || '',
            changed: onVendedorFormHandler
        },
        {
            inputType: "null",
            name: "district",
            isValidation: false,
            title: "Bairro",
            type: "text",
            value: inputItemsValue.district.value || '',
            changed: onVendedorFormHandler
        }, {
            inputType: "null",
            name: "street",
            title: "Rua",
            type: "text",
            isValidation: false,
            value: inputItemsValue.street.value || '',
            changed: onVendedorFormHandler
        },
        {
            inputType: "null",
            name: "PIS",
            title: "Pis",
            type: "text",
            isValidation: false,
            value: inputItemsValue.PIS.value || '',
            changed: onVendedorFormHandler
        },
        {
            inputType: "select",
            title: "Estado",
            isValidation: true,
            value: inputItemsValue.state.value || '',
            changed: onVendedorFormHandler,
            name: 'state',
            optionsItems: estados
        }
    ]
    const configProfile5 = [
        {
            inputType: "input",
            title: "Nome Completo",
            name: "full_name",
            type: "text",
            isValidation: true,
            value: inputItemsValue.full_name.value || '',
            changed: onVendedorFormHandler

        },
        {
            inputType: "input",
            title: "CPF",
            name: "CPF",
            type: "text",
            isValidation: true,
            value: inputItemsValue.CPF.value || '',
            changed: onVendedorFormHandler
        },
        {
            inputType: "input",
            title: "CEP",
            type: "text",
            name: "CEP",
            isValidation: true,
            value: inputItemsValue.CEP.value || '',
            changed: onVendedorFormHandler
        },
        {
            inputType: "input",
            name: "email",
            title: "E-mail",
            type: "email",
            isValidation: true,
            value: inputItemsValue.email.value || '',
            changed: onVendedorFormHandler
        },
        {
            inputType: "input",
            name: "district",
            title: "Bairro",
            type: "text",
            isValidation: true,
            value: inputItemsValue.district.value || '',
            changed: onVendedorFormHandler
        },
        {
            inputType: "input",
            name: "street",
            title: "Rua",
            type: "text",
            isValidation: true,
            value: inputItemsValue.street.value || '',
            changed: onVendedorFormHandler
        },
        {
            inputType: "input",
            title: "Cidade",
            name: "city",
            type: "text",
            isValidation: true,
            value: inputItemsValue.city.value || '',
            changed: onVendedorFormHandler
        },
        {
            inputType: "input",
            name: "PIS",
            title: "Pis",
            type: "text",
            isValidation: true,
            value: inputItemsValue.PIS.value || '',
            changed: onVendedorFormHandler
        },
        {
            inputType: "input",
            name: "phone",
            title: "Celular",
            type: "text",
            isValidation: true,
            value: inputItemsValue.phone.value || '',
            changed: onVendedorFormHandler
        },
        {
            inputType: "select",
            title: "Estado",
            isValidation: true,
            value: inputItemsValue.state.value || '',
            changed: onVendedorFormHandler,
            name: 'state',
            optionsItems: estados
        },
        {
            inputType: "null",
            title: "Telefone",
            name: "phone_2",
            isValidation: false,
            type: "text",
            value: inputItemsValue.phone_2.value || ''
        }
    ]
    const formTitle = <h1 className={classes.vendedoresForm__title}> CADASTRAR </h1>
    
    if (!userType) {
        return null
    } else {
        if (userType === 5) {
            config = configProfile5
        } else if (userType === 1) {
            config = configProfile1
        }
    }


    return (
        <>
            <Backdrop clicked={formVendedoresHandler} show={showFormVendedores} />
            <SomaForm className={classes.vendedoresForm} formTitle={formTitle} style={showFormVendedores ? { display: "block" } : { display: "none" }} config={config}>
                <span onClick={formVendedoresHandler} className={classes.close__btn}> X </span>
                <button className={classes.vendedoresForm__button} disabled={disabledbtn} onClick={OnSubmitForm}>SALVAR</button>
            </SomaForm>
        </>

    )
}

export default VendedoresForm


