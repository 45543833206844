import axios from 'axios';

export default axios.create({
  // baseURL: "http://localhost:8081/",
  baseURL: "https://mais-afiliados-backend.somalabs.com.br/",
  transformRequest: [(data, headers) => {
    const token = localStorage.getItem('CurUser');
    if (token) {
      headers.common.Authorization = `Bearer ${token}`;
    } else {
      console.log("Você está sem login.");
    }
    return data;
  }, ...axios.defaults.transformRequest]
});
