import React from 'react'
import classes from './LoginInput.module.css'



const loginInput = props => {
    return (
        <div className={classes.div__login__input}>
            <label className={classes.label__login__input}> {props.contentLabel} </label>
            <input type={props.type} name={props.name} autoComplete="off" className={classes.input_login_form} onChange={props.changed} value={props.value} />
        </div>
    ) 
}



export default loginInput