import MaisAfiliados from "./MaisAfiliados"



const createAffiliates = async (formData) => {
    try {
        return await MaisAfiliados.post("/v1/user/affiliates", formData)
    } catch (err) {
        throw err
    }
}

const getAffiliatesById = (id) => MaisAfiliados.get(`/v1/user/affiliates/id/${id}`)

const Affiliates = { createAffiliates, getAffiliatesById }


export default Affiliates