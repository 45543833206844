import React from 'react'
import classes from './LoginLinks.module.css'

const LoginLinks = props =>{


    return(
        <div className={classes.div__login__links}>
            <p className={classes.p__login__links}>{props.children} <span className={classes.login__links} onClick={props.clicked}>Clique aqui!</span></p>
        </div>
    )
}


export default LoginLinks