import React from 'react'
import classes from './BasePage.module.css'
import Menu from '../Menu/Menu'
import logoMaisAfiliado from '../../assets/img/logo+Afiliados.jpg'
import { useLogin } from '../../Hooks/UserContext'


const BasePage = props => {

    const logoff = useLogin()

    return (
        <>
            <div className={classes.main__menu}>
                <img className={classes.menu__brand} src={logoMaisAfiliado} alt="Logo Mais-Afiliados" />
                <div>
                    <span className={classes.logout__btn} onClick={logoff.logoff}> SAIR </span>
                </div>
            </div>
            <div className={classes.basepage__content}>
                <Menu />
                <div style={{ marginLeft: '250px', width: "100%" }}>{props.children}</div>
            </div>

        </>
    )
}

export default BasePage