import { Route, BrowserRouter, Switch } from 'react-router-dom';
import Home from './routes/home';
import MeusVendedores from './routes/MeusVendedores/MeusVendedores';
import PrivateRouter from './Services/AuthComponent/PrivateRouter';

const routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home} />
        <PrivateRouter exact path="/vendedores" component={MeusVendedores} />
      </Switch>
    </BrowserRouter>
  );
};

export default routes;
