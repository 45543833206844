import React, { useEffect, useState, useCallback, useMemo } from 'react'
import classes from './MeusVendedores.module.css'
import BasePage from '../../components/BasePage/BasePage'
import CardVendedor from '../../components/CardVendedor/CardVendedor'
import VendedoresForm from '../MeusVendedores/VendedoresForm/VendedoresForm'
import useUser from '../../Hooks/UserContext'
import AffiliatesService from '../../Services/affiliates'



const MeusVendedores = () => {

    const user = useUser()
    const [showFormVendedores, setShowFormVendedores] = useState(false)
    const [affiliatesConfig, setAffiliatesConfig] = useState({})


    const formVendedoresHandler = useCallback(() => {
        setShowFormVendedores(prevState => !prevState)
    }, [])

    const getAffiliatesByIdHandler = useCallback(async () => {
        if (!user) return
        const result = await AffiliatesService.getAffiliatesById(user.id_affiliates)
        setAffiliatesConfig(result.data.children)

    }, [user])

    useEffect(() => {
        getAffiliatesByIdHandler()
    }, [getAffiliatesByIdHandler])

    const vendedoresForm = useMemo(() => {
        return <VendedoresForm showFormVendedores={showFormVendedores} getAffiliatesById={getAffiliatesByIdHandler} formVendedoresHandler={formVendedoresHandler} />
    }, [getAffiliatesByIdHandler, showFormVendedores, formVendedoresHandler])


    return (
        <BasePage>
            {vendedoresForm}
            <div className={classes.meusvendedores__title}>
                <h1 className={classes.meusvendedores__title__item}>MEUS VENDEDORES </h1>
                <div className={classes.title__line}></div>
            </div>
            <div className={classes.lista__vendedores__conteiner}>
                <button className={classes.button__cadastrar__vendedor} onClick={formVendedoresHandler}>CADASTRAR VENDEDOR</button>
                <div className={classes.lista__vendedores}>
                    {
                        affiliatesConfig.length > 0 ?
                            affiliatesConfig.map(e => {
                                return <CardVendedor key={e.id_affiliates} config={e} />
                            })
                            : null
                    }
                </div>
            </div>
        </BasePage>
    )
}


export default MeusVendedores