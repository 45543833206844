import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import useUser from '../../Hooks/UserContext';


const PrivateRoute = (props) => {
  const user = useUser();

  const { children, component, to, redirect, ...RouteProps } = props;

  let show = false;

  if (user) {
    if (typeof to == 'string') {
      show = user.tipo.includes(to);
    }
    if (typeof to == 'function') {
      show = to(user.tipo);
    }
    if (Array.isArray(to)) {
      show = to.map((p) => user.tipo.includes(p)).reduce((a, b) => a || b)
    }
    if (!to) {
      show = true
    }
    if (typeof to == 'boolean') {
      show = to;
    }
  }

  if (show) {
    return (<Route {...RouteProps} render={() => user ? (component ? <props.component /> : children) : (<Redirect to="/Login" />)} />)
  } else {
    return <Route {...RouteProps}  ><Redirect to={redirect || '/'} /></Route>
  }

}
export default PrivateRoute