import React, { useCallback, useEffect, useState } from 'react';
import classes from "./LoginForm.module.css";
import LoginInput from './LoginInput/LoginInput';
import LoginLinks from './LoginLinks/LoginLinks';
import Validation from '../../../Helpers/Validation';
import logoMaisAfiliado from '../../../assets/img/logo+Afiliados.jpg';
import useUser, { useLogin } from '../../../Hooks/UserContext';
import { Redirect } from 'react-router';
import SomaForm from '../../../components/SomaForm/SomaForm';
import MicrosoftIcon from '../../../assets/icons/microsoftIcon.svg';
import useQuery from '../../../Hooks/useQuery';

const LoginForm = props => {
  const login = useLogin();
  const user = useUser();
  const query = useQuery();

  const [inputValueLogin, setInputValueLogin] = useState({
    email: {
      value: "",
      validationRules: {
        required: true,
        minLength: 4
      },
      valid: false
    },
    password: {
      value: "",
      validationRules: {
        required: true,
        minLength: 6
      },
      valid: false
    }
  });
  let disabledBtn = Validation.disabledBtnHandler(inputValueLogin);

  const inputLoginFormHandler = useCallback((e, type) => {
    const newInputs = {
      ...inputValueLogin,
      [type]: {
        ...inputValueLogin[type],
        value: e.target.value,
        valid: Validation.validationForms(type, inputValueLogin[type].validationRules, e.target.value)
      }
    };
    setInputValueLogin(newInputs);
  }, [inputValueLogin]);

  const onLoginHandler = useCallback((e, email, senha) => {
    e.preventDefault();
    return login.login(email, senha);
  }, [login]);

  useEffect(() => {
    const retokenDispatch = login.retoken;
    if (query.code) {
      retokenDispatch(query.code);
    }
  }, [query.code, login.retoken]);

  const formTitle = <img className={classes.logo__mais__afiliados} src={logoMaisAfiliado} alt="Logo +Afiliados" />;

  if (user) return <Redirect to="/Vendedores" />;

  return (
    <>
      <SomaForm className={classes.login__form} formTitle={formTitle}>
        <LoginInput type="email" contentLabel="E-mail" value={inputValueLogin.email.value} changed={(event) => inputLoginFormHandler(event, "email")} />
        <LoginInput type="password" name="password" contentLabel="Senha" value={inputValueLogin.password.value} changed={(event) => inputLoginFormHandler(event, "password")} />
        <div className={classes.div__btns__form}>
          <button className={classes.btn__form} onClick={(event) => onLoginHandler(event, inputValueLogin.email.value, inputValueLogin.password.value)} disabled={disabledBtn}>Entrar</button>
          <a href="https://login.microsoftonline.com/e48bd5e1-52cd-41f5-87b9-1d25c0d108b6/oauth2/v2.0/authorize?client_id=1156d1d9-122a-4af5-9038-f036473bd314&response_type=code&redirect_uri=https://mais-afiliados.somalabs.com.br/&response_mode=query&scope=openid%20email%20profile" className={classes.btn__form__microsoft}>
            <img src={MicrosoftIcon} alt="Entrar com Microsoft" />
            <span>Entrar com Microsoft</span>
            <div></div>
          </a>
        </div>

        <LoginLinks clicked={props.clickedModalCreate}> Não tem uma conta ? </LoginLinks>
        <LoginLinks clicked={null}> Esqueceu sua senha ? </LoginLinks>
      </SomaForm>
    </>
  );
};

export default LoginForm;
