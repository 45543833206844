import classes from './MenuButtons.module.css'
import React from 'react'
import { NavLink } from 'react-router-dom'

const menuButtons = props=>{

    return <NavLink exact activeClassName={classes.menu__item__active} to={props.link} className={classes.menubuttons}>{props.children}</NavLink>
}


export default menuButtons