import React from 'react'
import classes from './Backdrop.module.css'

const backdrop = props => {
    const { show, clicked } = props;

    return <div className={classes.backdrop} onClick={clicked} style={show ? { opacity:"1", transform: "translateX(0)", } : { opacity:"0",transform: "translateX(-100%)" }}></div>
}



export default backdrop