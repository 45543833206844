import React from 'react'
import classes from './SomaForm.module.css'
import FormComponent from './FormComponent/FormComponent'


const SomaForm = props => {

    const { children, config, formTitle, ...rest } = props
    return (
        <form onSubmit={(event) => event.preventDefault()} {...rest}>
            {formTitle}
            <div className={classes.form__itens}>
                {config ?
                    config.map(e => {
                        return <FormComponent
                            key={e.name}
                            inputType={e.inputType}
                            type={e.type}
                            value={e.value}
                            changed={e.changed}
                            title={e.title}
                            optionsItems={e.optionsItems}
                            name={e.name}
                            isValidation={e.isValidation} />
                    })
                    : null
                }
            </div>
            {children}
        </form>
    )
}

export default SomaForm