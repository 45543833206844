import React from 'react'
import classes from './FormComponent.module.css'

const FormComponent = props => {

    const { onChange, changed, title, name, type, value, component, children, optionsItems, inputType, isValidation, ...rest } = props
    let inputTypeItem = null

    if (inputType === "input") {
        inputTypeItem = <input className={classes.vendedoresFormInput__input} onChange={(event) => changed(event, name, isValidation)} type={type} value={value} {...rest} />
    } else if (inputType === "select") {
        inputTypeItem = <select className={classes.input__options__items} onChange={(event) => changed(event, name, isValidation)} value={value}  {...rest} > {optionsItems.map(e => <option key={e.value} value={e.value}> {e.text} </option>)} </select>
    }

    return (
        <div className={classes.vendedoresFormInput}  style={inputType === "null" ? { display: "none" } : { display: "flex" }}>
            <label className={classes.title}> {title} </label>
            {
                inputTypeItem
            }
        </div>
    )
}

export default FormComponent