import React, { useContext } from 'react'
import useApi from './useApi'
import Affiliates from '../Services/affiliates'


const AffiliatesContext = React.createContext()


const AffiliatesContextProvider = props =>{

    const createAffiliates = useApi(Affiliates.createAffiliates)



    return(
        <AffiliatesContext.Provider value={{  createAffiliates:createAffiliates.dispatch }}>
            {props.children}
        </AffiliatesContext.Provider>
    )
}


const useCreateAffiliates = () =>{
    const { createAffiliates }  = useContext(AffiliatesContext)
    
    return createAffiliates
}





export { AffiliatesContextProvider,useCreateAffiliates }